import { faClone } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';

import { maxWidth } from '@constants/media-queries';
import { space } from '@constants/spaces';
import Button from '@elements/Button';
import Input from '@elements/Input';
import List from '@elements/List';
import Title from '@elements/Title';

import DropdownWrapper from './DropdownWrapper';
import Notification from './Notification';

const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${space}px;

  ${maxWidth.mobile} {
    flex-wrap: wrap;
  }
`;

const StyledDropdownWrapper = styled(DropdownWrapper)`
  ${maxWidth.mobile} {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

type InviteLinkProps = {
  inviteURL: string;
  type: 'co-owner' | 'participant';
  [x: string]: any;
};

const InviteLink = ({ inviteURL, type, ...rest }: InviteLinkProps) => {
  const formMethods = useForm();
  const duration = 3000;
  const [shown, setShown] = useState(true);
  const { reset } = formMethods;

  useEffect(() => {
    reset({ 'invite-link': inviteURL });
  }, [reset, inviteURL]);

  const copyTextToClipboard = () => {
    setShown(true);
    if (!navigator.clipboard) return;
    navigator.clipboard
      .writeText(
        `${inviteURL} Doe je mee aan het boek? Het is super eenvoudig. Klik op de link en vul je pagina's. Je kan alles naar wens aanpassen en je bijdragen worden automatisch opgeslagen. Dank je wel!`,
      )
      .then(
        (value) => {
          setTimeout(() => {
            setShown(false);
          }, duration);
        },
        (error) => {
          console.error('Async: Could not copy text: ', error);
        },
      );
  };

  const points = [
    'deelnemers uitnodigen en verwijderen',
    'het hele boek bewerken',
    'het boek bestellen',
  ];

  const coOwnerContent = (
    <>
      <Title size="xxs" className="mb-8" as="h2">
        Beheerders kunnen...
      </Title>
      <List items={points} className="mb-32" />
    </>
  );

  const extra = () => {
    switch (type) {
      case 'co-owner':
        return coOwnerContent;
      default:
        return null;
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Form {...rest}>
        <Input name="invite-link" defaultValue={inviteURL} disabled />
        <StyledDropdownWrapper
          dropdownChildren={
            shown ? <Notification text="Link gekopiëerd!" /> : null
          }
          position="top"
          align="right"
          showMode="onClick"
        >
          <StyledButton
            iconLeft={faClone}
            onClick={copyTextToClipboard}
            onAnimationEnd={() => setShown(false)}
            data-gtm="share_book"
            genre="info"
            set="secondary"
          >
            Link kopiëren
          </StyledButton>
        </StyledDropdownWrapper>
      </Form>
      {extra()}
    </FormProvider>
  );
};

export default InviteLink;
