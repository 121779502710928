import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import {
  faCheckCircle,
  faExclamationTriangle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage } from '@hookform/error-message';
import get from 'lodash.get';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';

import {
  cta,
  danger,
  fontDark,
  fontLight,
  fontMedium,
  lineLight,
  success,
  fontDisabled,
} from '@constants/colors';
import { space } from '@constants/spaces';

type StyledInputProps = {
  focus: boolean;
  error: boolean;
  success: boolean;
  disabled: boolean;
};

type IconProps = {
  show: string;
  isPassword?: boolean;
};

const Component = styled.label`
  width: 100%;
  display: block;
  cursor: pointer;
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${fontMedium};
  text-align: left;
  cursor: pointer;
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

const StyledInput = styled.input<StyledInputProps>`
  width: 100%;
  height: 40px;
  display: block;
  padding: 0 40px 0 ${space * 1.5}px;
  border: 1px solid ${lineLight};
  font-size: 14px;
  color: ${({ disabled }) => (disabled ? fontDisabled : fontDark)};
  border-radius: 4px;
  transition: border-color 300ms ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
    -webkit-text-fill-color: ${fontLight};
  }

  ${({ focus }) => focus && `border-color: ${cta};`};
  ${({ error }) => error && `border-color: ${danger};`};
  ${({ success }) => success && `border-color: ${success};`};
`;

const Icon = styled(FontAwesomeIcon)<IconProps>`
  font-size: 16px;
  position: absolute;
  top: 50%;
  right: ${({ isPassword, show }) =>
    isPassword && show ? space * 4.5 : space * 1.5}px;
  transform: translate(30px, -50%);
  transition: transform 150ms ease;
  ${({ show }) =>
    show &&
    css`
      transform: translate(0, -50%);
      transition: transform 300ms ease-out 150ms;
    `};
`;

const SuccessIcon = styled(Icon)`
  color: ${success};
`;

const ErrorIcon = styled(Icon)`
  color: ${danger};
`;

const Error = styled.div`
  margin-top: 4px;
  font-size: 12px;
  height: 14px;
  color: ${danger};
  text-align: left;
  line-height: normal;
`;

const ToggleVisibilityIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${fontLight};
  position: absolute;
  top: 50%;
  right: ${space * 1.5}px;
  transform: translate(0, -50%);
  cursor: pointer;
  transition: transform 150ms ease;
`;

type InputProps = {
  className?: string;
  name: string;
  label?: string;
  defaultValue?: string;
  type?: 'text' | 'email' | 'tel' | 'password' | 'hidden';
  placeholder?: string;
  required?: boolean;
  feedback?: boolean;
  autoFocus?: boolean;
  onBlur?: () => void;
  disabled?: boolean;
  showError?: boolean;
  onChange?: () => void;
};

interface Rules {
  required: string | false;
}
interface Email extends Rules {
  pattern: object;
}
interface Password extends Rules {
  minLength: object;
}
interface Tel extends Rules {
  pattern: object;
  minLength: object;
  maxLength: object;
}

const Input = ({
  name,
  label,
  defaultValue,
  type = 'text',
  placeholder,
  required = false,
  feedback = true,
  autoFocus = false,
  onBlur,
  disabled = false,
  showError = false,
  onChange,
  ...rest
}: InputProps) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  const [focus, setFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const value = watch([name])[name as keyof typeof watch];
  const error = get(errors, name);

  const showErrorStyle = error && feedback;
  const showSuccessStyle = !disabled && !error && value && feedback;

  let registerData = {
    required: required ? 'Vul dit veld in' : required,
  };

  const handleBlur = () => {
    setFocus(false);
    if (onBlur) onBlur();
  };

  if (type === 'email') {
    registerData = {
      ...registerData,
      pattern: {
        value:
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Geen geldig e-mailadres',
      },
    } as Email;
  }

  if (type === 'tel') {
    registerData = {
      ...registerData,
      minLength: {
        value: 10,
        message: 'Telefoonnummer te kort',
      },
      maxLength: {
        value: 10,
        message: 'Telefoonnummer te lang',
      },
      pattern: {
        value: /^[0-9]*$/,
        message: 'Geen geldig telefoonnummer',
      },
    } as Tel;
  }

  if (type === 'password') {
    registerData = {
      ...registerData,
      minLength: {
        value: 8,
        message: 'Je wachtwoord moet uit minimaal 8 tekens bestaan',
      },
    } as Password;
  }

  return (
    <Component htmlFor={name} {...rest}>
      <Label>{label}</Label>
      <Container>
        <StyledInput
          type={showPassword ? 'text' : type}
          id={name}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onFocus={() => setFocus(true)}
          disabled={disabled}
          autoFocus={autoFocus}
          {...register(name, {
            ...registerData,
            onBlur: () => handleBlur(),
            onChange: () => onChange && onChange(),
          })}
          focus={focus}
          error={showErrorStyle}
          success={showSuccessStyle}
        />
        {type === 'password' && (
          <ToggleVisibilityIcon
            icon={showPassword ? faEyeSlash : faEye}
            onClick={() => setShowPassword(!showPassword)}
          />
        )}
        <SuccessIcon
          icon={faCheckCircle}
          show={showSuccessStyle ? 'true' : ''}
          isPassword={type === 'password'}
        />
        <ErrorIcon
          icon={faExclamationTriangle}
          show={showErrorStyle ? 'true' : ''}
          isPassword={type === 'password'}
        />
      </Container>
      {showError && (
        <Error>
          <ErrorMessage errors={errors} name={name} />
        </Error>
      )}
    </Component>
  );
};

export default Input;
