/* Brand colors */
export const brand = '#fa5c65';
export const background = '#fafbfb';
export const backgroundMedium = '#f7f8f9';
export const backgroundDark = '#414347';

export const backgroundColored = '#FFFDF8';
export const backgroundColoredMedium = '#FAF7F0';

/* Font Colors */
export const fontDark = '#2F3136';
export const fontMedium = '#54555A';
export const fontLight = '#8A94A6';
export const fontDisabled = '#B0B7C3';

/* Cta button color */
export const cta = '#fa5c65';
export const ctaHover = '#d94f57';
export const ctaOutline = '#d94f57';
export const ctaSecHover = '#fff0ef';
export const cta300 = '#ffe1df';

/* Primary */
export const primary100 = '#FFF6F6';
export const primary200 = '#fff0ef';
export const primary300 = '#FFE1DF';
export const primary700 = '#FA5C65';

/* Secondary */
export const secondary = '#6FBFD1';
export const secondary300 = '#e2f2f6';

/* Info button colors */
export const info = '#0284fe';
export const infoHover = '#2f77df';
export const infoOutline = '#01408f';
export const infoSecHover = '#EFF4F9';
export const info700 = '#0284FE';
export const info500 = '#83C3FE';
export const info300 = '#DCEEFF';
export const info200 = '#EEF7FF';
export const info100 = '#F8FBFF';

/* Success button colors */
export const success = '#36ab80';
export const successHover = '#4f977a';
export const successOutline = '#248953';
export const success300 = '#EAF7F1';
export const success700 = '#36AB80';

/* Warning button colors */
export const warning = '#f7af22';
export const warningHover = '#e4ab44';
export const warningOutline = '#f29738';
export const warning300 = '#FEF7E6';
export const warning700 = '#F7AF22';

/* Danger button colors */
export const danger = '#f4541d';
export const dangerHover = '#da5b32';
export const dangerOutline = '#d13a06';

/* Blacks */
export const black400 = '#54555A';
export const black900 = '#202225';

/* Borders */
export const lineDark = black400;
export const lineLight = '#E1E4E8';
export const grey200 = '#F7F8F9';
export const grey300 = '#F1F2F4';
export const grey400 = '#E1E4E8';
export const grey500 = '#C9CED6';
export const grey600 = '#B0B7C3';
export const placeholder = '#EEF7FF';

/* Info alt  colors */
export const infoAlt = '#553eb5';

/* Color Picker Options */
export const colorPickerOptions = [
  // First Row
  '#ffffff',
  '#FFF597',
  '#CBDD5F',
  '#91C0DA',
  '#FFB7CF',
  '#FF7070',
  // Second Row
  '#E4E0D8',
  '#FFE14A',
  '#ABD47D',
  '#679CD0',
  '#FF80A0',
  '#FF4040',
  // Third Row
  '#CBCDC4',
  '#FFDD00',
  '#53C54A',
  '#0095D7',
  '#E41072',
  '#ED1C24',
  // Fourth Row
  '#939598',
  '#FFA743',
  '#00B7AA',
  '#718DC7',
  '#955EA6',
  '#C44631',
  // Fifth Row
  '#4C4E56',
  '#E27D2C',
  '#59B297',
  '#005BAA',
  '#7E2675',
  '#AC262B',
  // Sixth Row
  '#231F20',
  '#9A4603',
  '#00A76D',
  '#042B64',
  '#4E0046',
  '#780207',
];
