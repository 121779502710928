import {
  faUsersGear,
  faUsers,
  faArrowRightLong,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import InviteLink from '@components/InviteLink';
import {
  black400,
  black900,
  brand,
  fontDark,
  fontLight,
  fontMedium,
  grey300,
  grey400,
  info700,
  lineLight,
} from '@constants/colors';
import { laptop, tablet } from '@constants/media-queries';
import { book as bookRoute, inviteUrl } from '@constants/routes';
import { space } from '@constants/spaces';
import Button from '@elements/Button';
import DialogFullPage from '@elements/DialogFullPage';
import Info from '@elements/Info';
import Radio from '@elements/Radio';
import Text from '@elements/Text';
import Title from '@elements/Title';
import {
  BookSpread,
  useBookSpreadCreateCollaborativeMutation,
  useBookSpreadToggleCollaborativeMutation,
  useGetBookInvitationTokenQuery,
} from '@graphql/generated/graphql';
import { useBookContext } from '@hooks/useBookContext';
import useMediaQuery from '@hooks/useMediaQuery';
import { getSpreadName } from '@utils/string';
import zindex from '@utils/zindex';

import ParticipantPrivacySettings from './ParticipantsPrivacySettings';
import SpreadCanvas from './SpreadCanvas';

const Component = styled.div`
  ${tablet} {
    min-width: 500px;
  }
`;

const IntroTitle = styled.h3`
  padding: ${space * 2}px;
`;

const Body = styled.div`
  padding: ${space * 2}px;
  max-width: 560px;
  ${tablet} {
    padding: ${space * 4}px;
  }
`;

const OptionItem = styled.li`
  list-style: none;
  &:last-child {
    margin-top: ${space * 2}px;
    margin-bottom: ${space * 4}px;
  }
`;

const OptionText = styled(Text)`
  color: ${black400};
`;

const Option = styled.button<{ selected: boolean }>`
  padding: ${space * 2}px;
  display: flex;
  background: none;
  cursor: pointer;
  color: ${fontLight};
  align-items: center;
  text-align: left;
  border: 2px solid ${grey400};
  border-radius: 8px;
  width: 100%;

  ${({ selected }) =>
    selected &&
    css`
      border-color: ${brand};

      & > svg {
        color: ${brand};
      }

      & ${OptionText} {
        color: ${black900};
      }
    `};

  &:hover,
  &:focus {
    border-color: ${brand};

    & > svg {
      color: ${brand};
    }

    & ${OptionText} {
      color: ${black900};
    }
  }

  ${tablet} {
    padding: ${space * 3}px;
  }
`;

const StyledButton = styled(Button)`
  display: block;
  margin: 0 auto;
`;

const HeaderActions = styled.div`
  padding-top: ${space}px;
  padding-bottom: ${space}px;
  padding-left: ${space * 2}px;
  padding-right: ${space * 2}px;
  border-bottom: 1px solid ${grey400};
  position: sticky;
  top: 0;
  background: white;
  z-index: ${zindex('sticky-header')};

  & > div {
    margin: 0 auto;
    max-width: 1400px;
    display: flex;
    justify-content: space-between;
  }
  ${tablet} {
    padding-left: ${space * 3}px;
    padding-right: ${space * 3}px;
  }

  ${laptop} {
    padding-left: ${space * 5}px;
    padding-right: ${space * 4}px;
  }
`;

const ParticipantIntro = styled.div`
  padding: ${space * 3}px ${space * 2}px ${space * 4}px;
  text-align: center;

  ${tablet} {
    padding: ${space * 7}px 0 ${space * 10}px;
  }
`;

const Number = styled.b<{ selected?: boolean }>`
  color: ${({ selected }) => (selected ? info700 : 'inherit')};
`;

const Wrapper = styled.div<{ summary?: boolean }>`
  padding: ${space * 4}px ${space * 2}px;
  background-color: ${({ summary }) => !summary && grey300};
  flex-grow: 1;

  ${tablet} {
    padding: ${({ summary }) =>
      summary ? `0 0 ${space * 4}px 0` : `${space * 4}px 0`};
  }
`;

const Container = styled.div<{ summary?: boolean }>`
  margin: 0 auto;
  max-width: ${({ summary }) => (summary ? '700px' : '500px')};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Spread = styled.div<{ summary?: boolean }>`
  &:not(:last-of-type) {
    margin-bottom: ${({ summary }) =>
      summary ? `${space * 3}px` : `${space}px`};
  }
`;

const PreviewPage = styled.div<{ selected?: boolean }>`
  background-color: white;
  border: 1px solid ${lineLight};
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  cursor: ${({ selected }) => (selected === undefined ? 'default' : 'pointer')};

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 3px solid ${({ selected }) => (selected ? info700 : 'transparent')};
  }
`;

const PageName = styled.div`
  margin: ${space / 2}px 0 ${space / 2}px;
  color: ${fontLight};
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
`;

const RadioContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const InviteContainer = styled.div`
  padding: ${space * 4}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  max-width: 100vw;

  ${tablet} {
    padding: ${space * 4}px 0;
    max-width: 555px;
  }
`;

const InviteTitle = styled(Title)`
  text-align: center;
`;

const StyledText = styled(Text)`
  text-align: center;
  color: ${fontMedium};
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 27px;
  margin-right: ${space * 3}px;
`;

const SuccessContainer = styled.div`
  padding: ${space * 3}px;

  ${tablet} {
    padding: ${space * 6}px ${space * 8}px;
  }
`;

const SuccessTitle = styled(Text)`
  max-width: 500px;
  margin: 0 auto ${space * 6}px;
  color: ${fontDark};
  text-align: center;
`;

const EditorButton = styled(Button)`
  margin: ${space * 4}px 0 ${space * 3}px;
  width: fit-content;
  align-self: center;
`;

type Step =
  | 'intro'
  | 'coOwnerInvite'
  | 'coOwnerSuccess'
  | 'participantSelectSpreads'
  | 'participantConfirmSpreads'
  | 'participantInvite'
  | 'participantSuccess';
type Option = '' | 'coOwnerInvite' | 'participantSelectSpreads';

const DialogInvite = () => {
  const router = useRouter();
  const [selectedSpreads, setSelectedSpreads] = useState<string[]>([]);
  const [ownerPage, setOwnerPage] = useState<string>('');
  const [step, setStep] = useState<Step>('intro');
  const [option, setOption] = useState<Option>('');
  const {
    query: { bookId },
  } = router;
  const { refetchBook, book } = useBookContext();
  const { data: inviteToken } = useGetBookInvitationTokenQuery({
    variables: { bookID: bookId },
    skip: !bookId,
  });
  const { isMobile } = useMediaQuery();
  const [bookSpreadToggleCollaborative] =
    useBookSpreadToggleCollaborativeMutation();
  const [bookSpreadCreateCollaborative] =
    useBookSpreadCreateCollaborativeMutation();
  const displaySpread = (i: number, spread: BookSpread) =>
    i !== 0 &&
    i !== 1 &&
    i !== book.spreads.length - 1 &&
    !spread.isTemplate &&
    !spread.isCollaborative;

  // Navigate to editor when popstate is triggered
  useEffect(() => {
    const handlePopState = () => {
      router.push(bookRoute(bookId as string, ''));
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [router, bookId]);

  const filterProducts = (id: string) => {
    if (selectedSpreads.indexOf(id) > -1)
      setSelectedSpreads(selectedSpreads.filter((value) => value !== id));
    else {
      setSelectedSpreads([...selectedSpreads, id]);
    }
  };

  const goToEditor = () => {
    setStep('participantSuccess');
  };

  const createOwnerPages = () => {
    bookSpreadCreateCollaborative({
      variables: {
        bookID: bookId,
      },
      onCompleted: (data) => {
        refetchBook();
        setOwnerPage(data.bookSpreadCreateCollaborative);
      },
    });
  };

  const goToParticipantInvite = async () => {
    await Promise.all(
      selectedSpreads.map(async (spreadId, i) => {
        await bookSpreadToggleCollaborative({
          variables: { spreadId },
        });
      }),
    );
    createOwnerPages();
    setStep('participantInvite');
  };

  const intro = (
    <>
      <IntroTitle>Wie wil je uitnodigen?</IntroTitle>
      <Body>
        <ul>
          <OptionItem>
            <Option
              onClick={() => setOption('participantSelectSpreads')}
              selected={option === 'participantSelectSpreads'}
            >
              <Icon icon={faUsers} />
              <div>
                <OptionText>
                  Deelnemers{' '}
                  <Info align={isMobile ? 'right' : 'left'}>
                    Handig voor grotere groepen.
                  </Info>
                </OptionText>
                {isMobile ? (
                  <p>Mogen eigen pagina&apos;s bewerken</p>
                ) : (
                  <p>Mogen alleen eigen pagina&apos;s bewerken</p>
                )}
              </div>
            </Option>
          </OptionItem>
          <OptionItem>
            <Option
              onClick={() => setOption('coOwnerInvite')}
              selected={option === 'coOwnerInvite'}
            >
              <Icon icon={faUsersGear} />
              <div>
                <OptionText>Mede-beheerder</OptionText>
                <p>Kan alle pagina&apos;s bewerken</p>
              </div>
            </Option>
          </OptionItem>
        </ul>
        <StyledButton
          disabled={!option}
          onClick={() => setStep(option as Step)}
        >
          Volgende
        </StyledButton>
      </Body>
    </>
  );

  const participantSelectSpreads = (
    <DialogFullPage>
      <HeaderActions>
        <div>
          <Link href={bookRoute(bookId as string, '')} passHref>
            <Button set="tertiary">Terug naar boek</Button>
          </Link>
          <Button
            iconRight={faArrowRightLong}
            disabled={!selectedSpreads.length}
            onClick={() => setStep('participantConfirmSpreads')}
          >
            Volgende
          </Button>
        </div>
      </HeaderActions>
      <ParticipantIntro>
        <Title size="nm">
          Selecteer deelnemerpagina&apos;s
          <Info position="bottom" align={isMobile ? 'left' : 'left'}>
            <b>Wat zijn deelnemerpagina&apos;s?</b>
            <br />
            <span>
              De pagina&apos;s die je hier selecteert worden omgezet naar
              deelnemerpagina’s. Na het accepteren van je uitnodiging ontvangen
              deelnemers hiervan een kopie.
            </span>
          </Info>
        </Title>
        <Text size="nm" className="mt-8">
          Jij en deelnemers ontvangen automatisch een kopie van deze{' '}
          <Number selected={!!selectedSpreads.length}>
            ({selectedSpreads.length * 2})
          </Number>{' '}
          pagina&apos;s.
        </Text>
      </ParticipantIntro>
      <Wrapper>
        <Container>
          {book?.spreads.map((spread, i) => {
            if (!displaySpread(i, spread)) return null;
            return (
              <Spread key={i}>
                <PreviewPage
                  onClick={() => filterProducts(spread.id)}
                  selected={selectedSpreads.indexOf(spread.id) > -1}
                >
                  <SpreadCanvas spread={spread} />
                  <RadioContainer>
                    <Radio
                      name="spread"
                      checked={selectedSpreads.indexOf(spread.id) > -1}
                      onChange={() => filterProducts(spread.id)}
                    />
                  </RadioContainer>
                </PreviewPage>
                <PageName>
                  {getSpreadName(i, book.spreads.length, false)}
                </PageName>
              </Spread>
            );
          })}
        </Container>
      </Wrapper>
    </DialogFullPage>
  );

  const participantConfirmSpreads = (
    <DialogFullPage>
      <HeaderActions>
        <div>
          <Button
            set="tertiary"
            onClick={() => setStep('participantSelectSpreads')}
          >
            Vorige
          </Button>
          <Button iconRight={faArrowRightLong} onClick={goToParticipantInvite}>
            Deelnemers uitnodigen
          </Button>
        </div>
      </HeaderActions>
      <ParticipantIntro>
        <Title size="nm">
          Deelnemers ontvangen een kopie van deze pagina&apos;s
        </Title>
      </ParticipantIntro>
      <Wrapper summary>
        <Container summary>
          {book?.spreads
            .filter((spread) => selectedSpreads.indexOf(spread.id) > -1)
            .map((spread, i) => (
              <Spread key={i} summary>
                <PreviewPage key={i}>
                  <SpreadCanvas spread={spread} />
                </PreviewPage>
              </Spread>
            ))}
        </Container>
      </Wrapper>
    </DialogFullPage>
  );

  const coOwnerInvite = (
    <DialogFullPage>
      <InviteContainer>
        <div>
          <InviteTitle size="nm" className="mb-8">
            Deel de link via WhatsApp, mail etc
          </InviteTitle>
          <StyledText size="nm" className="mb-40">
            Je kan deze link voor alle deelnemers gebruiken.
          </StyledText>
          <InviteLink
            className="mb-24"
            type="co-owner"
            inviteURL={inviteUrl(
              bookId as string,
              inviteToken?.bookInvitationToken || '',
            )}
          />
        </div>
        <EditorButton
          onClick={() => setStep('coOwnerSuccess')}
          set="primary"
          iconRight={faArrowRightLong}
        >
          Naar het boek
        </EditorButton>
      </InviteContainer>
    </DialogFullPage>
  );

  const coOwnerSuccess = (
    <SuccessContainer>
      <SuccessTitle size="md">
        Zodra beheerders je uitnodiging accepteren krijgen zij volledige toegang
        tot je boek.
      </SuccessTitle>
      <StyledButton
        onClick={() => router.push(bookRoute(bookId as string, ''))}
      >
        Oké, ik snap het
      </StyledButton>
    </SuccessContainer>
  );

  const participantInvite = (
    <DialogFullPage>
      <InviteContainer>
        <div>
          <InviteTitle size="nm" className="mb-8">
            Deel de link via WhatsApp, mail etc
          </InviteTitle>
          <StyledText size="nm" className="mb-40">
            Je kan deze link voor alle deelnemers gebruiken.
          </StyledText>
          <InviteLink
            className="mb-24"
            type="participant"
            inviteURL={inviteUrl(
              bookId as string,
              inviteToken?.bookInvitationToken || '',
            )}
          />
          <ParticipantPrivacySettings bookId={bookId as string} />
        </div>
        <EditorButton
          onClick={goToEditor}
          set="primary"
          iconRight={faArrowRightLong}
        >
          Naar het boek
        </EditorButton>
      </InviteContainer>
    </DialogFullPage>
  );

  const participantSuccess = (
    <SuccessContainer>
      <SuccessTitle size="md">
        Zodra deelnemers je uitnodiging accepteren, verschijnen hun
        pagina&apos;s automatisch in jullie boek.
      </SuccessTitle>
      <StyledButton
        onClick={() => router.push(bookRoute(bookId as string, ownerPage))}
      >
        Naar mijn pagina&apos;s
      </StyledButton>
    </SuccessContainer>
  );

  const renderStep = () => {
    switch (step) {
      case 'intro':
        return intro;
      case 'coOwnerInvite':
        return coOwnerInvite;
      case 'participantSelectSpreads':
        return participantSelectSpreads;
      case 'coOwnerSuccess':
        return coOwnerSuccess;
      case 'participantConfirmSpreads':
        return participantConfirmSpreads;
      case 'participantInvite':
        return participantInvite;
      case 'participantSuccess':
        return participantSuccess;
      default:
        return intro;
    }
  };

  return <Component>{renderStep()}</Component>;
};

export default DialogInvite;
