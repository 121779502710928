import { IconLookup } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { backgroundDark } from '@constants/colors';

const Component = styled.div`
  padding: 8px 12px;
  color: #ffffff;
  background: ${backgroundDark};
  border-radius: 8px;
  max-width: 500px;
  & > svg {
    margin-left: 8px;
  }
`;

const Notification = ({ text, icon }: { text: string; icon?: IconLookup }) => {
  return (
    <Component>
      {text}
      {icon && <FontAwesomeIcon icon={icon} />}
    </Component>
  );
};

export default Notification;
