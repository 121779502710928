import {
  faClone,
  faTrashAlt,
  faUsers,
  faGear,
  faUsersSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState, useContext } from 'react';
import styled from 'styled-components';

import {
  brand,
  fontDark,
  fontLight,
  fontMedium,
  grey400,
  info,
  lineLight,
} from '@constants/colors';
import {
  book as bookRoute,
  inviteSearch,
  templateCollaborativePages,
} from '@constants/routes';
import { space } from '@constants/spaces';
import DialogContext from '@context/DialogContext';
import Badge from '@elements/Badge';
import InView from '@elements/InView';
import {
  BookSpread,
  MemberRole,
  useBookSpreadDuplicateMutation,
  useBookSpreadToggleCollaborativeMutation,
  useOnSpreadThumbnailUpdateSubscription,
  useSpreadRemoveMutation,
} from '@graphql/generated/graphql';
import { Book } from '@graphql/generated/page';
import { useBookContext } from '@hooks/useBookContext';
import useMediaQuery from '@hooks/useMediaQuery';
import { findSpreadIndex } from '@utils/spread';

import DropdownMenu from './DropdownMenu';
import DropdownWrapper from './DropdownWrapper';
import SpreadCanvas from './SpreadCanvas';
import SpreadLockOverlay from './SpreadLockOverlay';
import SpreadThumbnail from './SpreadThumbnail';

const Component = styled.div`
  position: relative;
  overflow: visible;
`;

const Menu = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  top: -8px;
  right: -10px;
`;

const MenuButton = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${grey400};
  border-radius: 50%;
  box-shadow: 0px 0px 1px 0px rgba(10, 22, 70, 0.06),
    0px 6px 6px -1px rgba(10, 22, 70, 0.1);
  transition: background-color 200ms ease;
  cursor: pointer;

  & > svg {
    color: ${fontMedium};
  }

  &:hover > svg {
    color: ${fontDark};
  }
`;

const Preview = styled.div<{ active: boolean; disabled: boolean }>`
  width: 100%;
  aspect-ratio: 2 / 1;
  position: relative;
  background-color: white;
  border: 2px solid ${({ active }) => (active ? brand : lineLight)};
  border-radius: 10px;
  transition: border-color 200ms ease;
  overflow: hidden;
  z-index: 0;
  cursor: ${({ disabled }) => (disabled ? 'pointer' : 'grab')};

  &:hover {
    border-color: ${brand};
  }

  & > * {
    pointer-events: none;
  }
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  bottom: 26px;
  max-width: 80%;
  color: white;
  right: 5px;
  padding: 0 ${space}px;
  border-radius: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
  background-color: ${brand};
`;

const Collaborate = styled.div<{ color: string; isTemplate: boolean }>`
  padding: 2px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color || info};
  color: white;
  border-radius: 6px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  width: max-content;

  & > svg {
    margin-right: 4px;
  }
`;

const Title = styled.div`
  margin: ${space / 4}px 0 ${space / 2}px;
  color: ${fontLight};
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
`;

type Props = {
  spread: BookSpread;
  title: string;
  disabled?: boolean;
  book: Book;
};

const SpreadPreview = ({
  spread: spreadParam,
  title,
  disabled = false,
  book,
}: Props) => {
  const router = useRouter();
  const {
    query: { bookId, spreadId, invite },
  } = router;
  const [spread, setSpread] = useState<BookSpread>(spreadParam);
  const [thumnailAvailable, setThumbnailAvailable] = useState(
    spreadParam.thumbnailURL && spreadParam.thumbnailURL?.length > 0,
  );
  const [isHovered, setIsHovered] = useState(false);
  const { isLaptop, isTablet } = useMediaQuery();
  const { isAdmin, isOwner, isParticipant } = useBookContext();
  const { openDialog, closeDialog } = useContext(DialogContext);

  useOnSpreadThumbnailUpdateSubscription({
    skip: !book.id,
    shouldResubscribe: true,
    variables: { bookID: book.id },
    onSubscriptionData: async ({ subscriptionData }) => {
      if (subscriptionData.data) {
        const updatedSpread = spread;
        if (
          updatedSpread.id ===
          subscriptionData.data.spreadThumbnailUpdate?.spreadID
        ) {
          updatedSpread.thumbnailURL =
            subscriptionData.data.spreadThumbnailUpdate?.thumbnailURL;
          setSpread({ ...updatedSpread });
        }
      }
    },
  });

  const [spreadRemove] = useSpreadRemoveMutation();
  const [bookSpreadToggleCollaborative] =
    useBookSpreadToggleCollaborativeMutation();
  const [bookSpreadDuplicate] = useBookSpreadDuplicateMutation();

  const isActiveSpread = router.query.spreadId === spread?.id;
  const previewRef = useRef<HTMLDivElement>(null);
  const isOnTemplatePage =
    router.asPath ===
    templateCollaborativePages(bookId as string, spreadId as string);
  const { isCollaborative } = spread;
  const isActive = spread?.id === spreadId;
  const spreadsIDs = book.spreads
    ?.filter((spread) =>
      isOnTemplatePage ? spread?.isTemplate : !spread?.isTemplate,
    )
    .map((spread) => spread?.id);
  const moreThanOneTemplate =
    book.spreads?.filter((spread) => spread?.isTemplate) &&
    book.spreads?.filter((spread) => spread?.isTemplate).length > 1;

  const scrollTo = (el: HTMLDivElement) => {
    const parentEl = el.parentNode as HTMLUListElement;
    const elLeft = el.offsetLeft + el.offsetWidth;
    const elParentLeft = parentEl?.offsetLeft + parentEl.offsetWidth;

    // check if element not in view
    if (elLeft >= elParentLeft + parentEl.scrollLeft) {
      parentEl.scrollLeft = elLeft - elParentLeft;
    } else if (elLeft <= parentEl.offsetLeft + parentEl.scrollLeft) {
      parentEl.scrollLeft = el.offsetLeft - parentEl.offsetLeft;
    }
  };

  const isCoverPage = spread?.id === spreadsIDs[0] && !isOnTemplatePage;
  const isFirstPage = spread?.id === spreadsIDs[1] && !isOnTemplatePage;
  const isLastPage =
    spread?.id === spreadsIDs[spreadsIDs.length - 1] && !isOnTemplatePage;

  const handleMouseEnter = () => {
    if (!('ontouchstart' in window)) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!('ontouchstart' in window)) {
      setIsHovered(false);
    }
  };

  // on initial page load if a specific spread is selected -> check if it needs to be scrolled to
  useEffect(() => {
    const element = previewRef?.current;
    if (isActiveSpread && element) {
      // as the page renders and this function runs the parent container might not have the overflow property just yet
      // resulting in parent scrollLeft property being 0 -> the hidden child doesn't get scrolled into view
      let timer1 = setTimeout(() => scrollTo(element), 200);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, []);

  // on book navigation
  useEffect(() => {
    if (isActiveSpread && previewRef?.current) {
      scrollTo(previewRef.current);
    }
  }, [isActiveSpread]);

  const duplicateActivePage = () => {
    bookSpreadDuplicate({
      variables: {
        spreadId: spread?.id,
      },
    });
  };

  const deleteActivePage = async () => {
    const currentIndex = spreadsIDs.indexOf(spread?.id);

    const navToPage =
      currentIndex === 0
        ? spreadsIDs[1] || spreadsIDs[0]
        : spreadsIDs[currentIndex - 1] ||
          spreadsIDs[currentIndex + 1] ||
          spreadsIDs[0];

    spreadRemove({
      variables: { spreadId: spread?.id },
      onCompleted: () => {
        closeDialog();
        router.push(
          isOnTemplatePage
            ? templateCollaborativePages(bookId as string, navToPage)
            : bookRoute(bookId as string, navToPage),
        );
      },
    });
  };

  const confirmDeletePage = () => {
    const hideDeleteSpreadDialog = Cookies.get('hideDeleteSpreadDialog');
    if (hideDeleteSpreadDialog === 'true') {
      deleteActivePage();
    } else {
      openDialog('delete-spread', {
        spreadId: spread?.id,
        onDelete: deleteActivePage,
      });
    }
  };

  const enableCollaborate = () => {
    bookSpreadToggleCollaborative({
      variables: { spreadId: spread?.id },
    });
  };

  const setActive = () => {
    if (isOwner && isLaptop && isOnTemplatePage) {
      router.push(
        templateCollaborativePages(bookId as string, spread?.id as string),
      );
    } else {
      router.push({
        pathname: bookRoute(bookId as string, spread?.id as string),
        search: invite ? inviteSearch(invite as string) : '',
      });
    }
  };

  const pageDropdown = [
    {
      name: 'Dupliceren',
      onClick: duplicateActivePage,
      icon: faClone,
      show: spread?.isDuplicatable,
    },
    {
      name: 'Verwijder pagina',
      onClick: confirmDeletePage,
      icon: faTrashAlt,
      show: isAdmin || (spread?.isTemplate ? moreThanOneTemplate : true),
    },
    {
      name: isCollaborative ? 'Maak single-user' : 'Maak multi-user',
      onClick: enableCollaborate,
      icon: isCollaborative ? faUsersSlash : faUsers,
      show: isAdmin && book.isTemplate,
    },
  ];
  const pageDropdownComponent = <DropdownMenu items={pageDropdown} />;
  const color = info;
  //TODO: add different colors per user
  // const color = (spread?.author && pages.participants.find((pt) => pt.account === currentPage.author._id) && pages.participants.find((pt) => pt.account === currentPage.author._id).color);

  return (
    <Component
      ref={previewRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Preview
        active={isActive}
        onClick={setActive}
        disabled={!spread?.isEditable || disabled}
      >
        {/* TODO: rules for locking preview */}
        {/* Logged in user is not book owner or not page author */}
        {!book.isTemplate && !spread?.isEditable && <SpreadLockOverlay />}
        <InView>
          <>
            {spread?.thumbnailURL ? (
              <SpreadThumbnail spread={spread} />
            ) : (
              <SpreadCanvas
                spread={spread}
                isHidden={
                  !book.isTemplate &&
                  (isParticipant ? false : isAdmin ? false : true)
                }
              />
            )}
          </>
        </InView>
      </Preview>
      {spread?.isEditable &&
        !disabled &&
        !isCoverPage &&
        !isFirstPage &&
        !isLastPage &&
        (isAdmin ||
          (isOwner &&
            ((spread?.isTemplate && isOnTemplatePage) ||
              (!spread?.isTemplate && !isOnTemplatePage))) ||
          book.memberRole === MemberRole.CoOwner) && (
          <Menu show={isActive || isHovered}>
            <DropdownWrapper
              dropdownChildren={pageDropdownComponent}
              align={isTablet ? 'right' : 'left'}
              position="top"
            >
              <MenuButton onClick={setActive}>
                <FontAwesomeIcon icon={faGear} />
              </MenuButton>
            </DropdownWrapper>
          </Menu>
        )}
      {spread?.isCollaborative &&
        (spread?.isTemplate ? (
          <Collaborate color={color} isTemplate={spread?.isTemplate}>
            <FontAwesomeIcon icon={faUsers} />
            Sjabloon{' '}
            {isOnTemplatePage
              ? findSpreadIndex(
                  book.spreads.filter((spr) => spr.isTemplate),
                  spread?.id,
                ) + 1
              : ''}
          </Collaborate>
        ) : (
          <StyledBadge>
            {`${spread?.author?.firstName || 'Onbekend'}`}
          </StyledBadge>
        ))}
      <Title>{title}</Title>
    </Component>
  );
};

export default SpreadPreview;
