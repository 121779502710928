import styled from 'styled-components';

import EditorHeader from '@components/EditorHeader';
import MobileHeader from '@components/mobile/MobileHeader';
import useMediaQuery from '@hooks/useMediaQuery';
import zindex from '@utils/zindex';

const Component = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: ${zindex('dialog')};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

type DialogProps = {
  open?: boolean;
  children?: React.ReactNode;
};

const DialogFullPage = ({ children = null }: DialogProps) => {
  const { isTablet } = useMediaQuery();

  return (
    <Component>
      {isTablet ? <MobileHeader /> : <EditorHeader />}
      {children}
    </Component>
  );
};

export default DialogFullPage;
