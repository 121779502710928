import { isBrowser } from './live-prep';

// General urls
export const logout = '/uitloggen';

// Dialog urls
export const login = '#login';
export const signup = '#signup';
export const forgot = '#forgot';
export const books = '#boeken';
export const invite = '#invite';
export const remind = '#remind';
export const bookChoice = '#bookChoice';

// Shop urls
// User
export const allProducts = '/fotoboeken';
export const productBook = (slug: string) => `${allProducts}/${slug}`;
export const faq = '/klantenservice';
export const privacy = '/privacy';
export const terms = '/algemene-voorwaarden';
export const ourStory = '/ons-verhaal';
export const userAccount = '/account';
export const prices = '/prijzen';
export const exampleBooks = '/voorbeelden';
export const durability = '/duurzaamheid';
export const explanation = '/uitleg-samen-maken';
export const userAccountOrders = `${userAccount}/bestellingen`;
export const userAccountOrderDetails = (id: string) =>
  `${userAccountOrders}/${id}`;
export const userAccountDetails = `${userAccount}/details`;
export const userAccountDetailsPersonal = `${userAccountDetails}/persoonsgegevens`;
export const userAccountDetailsAddress = `${userAccountDetails}/adres`;
export const userAccountDetailsSecurity = `${userAccountDetails}/beveiliging`;
export const userAccountDetailsNewsletter = `${userAccountDetails}/nieuwsbrief`;

// Custom landing pages
export const landingPageColleague = '/het-collegaboek';
export const landingPageBirthday = '/het-verjaardagsboek';
export const landingPageDiner = '/het-21-dinerboek';

// Admin
export const admin = '/admin';
export const adminTemplates = '/admin/sjablonen';
export const adminStickers = '/admin/stickers';
export const adminFrames = '/admin/kaders';
export const adminBackrounds = '/admin/achtergronden';

// Book urls
export const book = (bookId: string, spreadId: string) =>
  `/boek/${bookId}/${spreadId}`;
export const templateCollaborativePages = (bookId: string, spreadId: string) =>
  `/template-samen-maken/${bookId}/${spreadId}`;
export const inviteSearch = (invite: string) => `invite=${invite}`;
export const withInvite = (route: string, invite: string) =>
  `${route}?${inviteSearch(invite)}`;
export const pricing = (bookId: string) => `/prijsoverzicht/${bookId}`;

// Invites urls
export const invites = (bookId: string) => `/deelnemer-overzicht/${bookId}`;
export const inviteUrl = (bookId: string, token: string) => {
  if (!isBrowser)
    return `${productionUrl}${book(bookId, '')}?${inviteSearch(token)}`;
  return `${window.location.origin}${book(bookId, '')}?${inviteSearch(token)}`;
};

// Print urls
export const print = '/print';
export const printBook = (bookId: string, spreadId: string) =>
  `${print}/${bookId}/${spreadId || ''}`;

// Book ids
export const bookBlank = book(
  process.env.NODE_ENV === 'production'
    ? '662a52e9-8367-45fc-8637-80de0f412fc4'
    : '2e15afef-d531-4299-86d7-d987acee8c47',
  '',
);
export const bookColleague = book(
  process.env.NODE_ENV === 'production'
    ? 'a2caa559-8bf6-4a40-81f1-f40cff19a326'
    : '1f37718b-c4b5-414b-9b1e-8df99cca8b8b',
  '',
);
export const bookBirthday = book(
  process.env.NODE_ENV === 'production'
    ? '33a139bb-8c60-47c6-830e-7979ff800f1e'
    : 'd4014f54-abaa-46d5-b6b6-02e9bf9f625f',
  '',
);
export const bookDiner = book(
  process.env.NODE_ENV === 'production'
    ? '5e993f40-0127-4937-b614-bf36f119dfd7'
    : 'c4f5323e-bf95-4384-ac7e-cd05e09ad654',
  '',
);
export const bookRetirement = book(
  process.env.NODE_ENV === 'production'
    ? 'dfc18507-d295-4d1a-9eb6-8af914fb2256'
    : '',
  '',
);
export const bookMemory = book(
  process.env.NODE_ENV === 'production'
    ? '24a96312-58af-4129-9a79-7387bb4aa28e'
    : '',
  '',
);
export const bookAnniversary = book(
  process.env.NODE_ENV === 'production'
    ? '0c5cbb7d-0472-40da-85df-0d803a9b59e1'
    : '',
  '',
);
export const bookWedding = book(
  process.env.NODE_ENV === 'production'
    ? '6d14ccf9-28b3-469e-949d-7caed305f88c'
    : '',
  '',
);
export const bookHolliday = book(
  process.env.NODE_ENV === 'production'
    ? 'f42957eb-5b4c-41ee-8319-c03d42c90496'
    : '',
  '',
);

// External urls
export const belmondoWebsite = 'https://belmondo.com/';
export const instargram = 'https://www.instagram.com/belmondofoto.nl/';
export const pinterest = 'https://nl.pinterest.com/belmondofoto/';
export const facebook = 'https://www.facebook.com/belmondofoto.nl';
export const trustpilot = 'https://nl.trustpilot.com/review/belmondofoto.nl';
export const productionUrl = 'https://belmondofoto.nl';
export const googleReviews =
  'https://www.google.com/search?sca_esv=d497ec08d6a1353d&rlz=1C5CHFA_enNL1022NL1022&sxsrf=ADLYWII21Ky5bsLZnPIxc3v99_Y2yWCKJQ:1714997288101&uds=ADvngMjcH0KdF7qGWtwTBrP0nt7dGVJwzLXtGGSifhrU9H3-aaiuAvUzDGORIl14eBCidwDsj4vGaIOhky4vEk6sjWTXz3PdBYj9ygdochc2AyIz04GG3dHsl4rFLs0tmIjp-8Wky0h3&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7sHUenkNwKCia6SsHKk6tMbUX2Pue6dZv04FxXenUA15Lnyk8WY2W7PZ7IuNpVDmneiRdlU%3D&q=Belmondofoto.nl+Reviews&sa=X&ved=2ahUKEwjUmpfh_viFAxUSg_0HHdrzBW8Q3PALegQITxAF&biw=1680&bih=858&dpr=2';
