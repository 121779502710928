import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

import {
  cta,
  ctaOutline,
  fontDark,
  fontLight,
  fontMedium,
  secondary,
} from '@constants/colors';
import { laptop, smallTablet, tablet } from '@constants/media-queries';
import { bookBlank, exampleBooks, prices } from '@constants/routes';
import { space } from '@constants/spaces';
import { themes } from '@constants/themes-data';
import Badge from '@elements/Badge';
import Button from '@elements/Button';
import Info from '@elements/Info';
import Text from '@elements/Text';
import Title from '@elements/Title';
import useMediaQuery from '@hooks/useMediaQuery';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${space * 2}px ${space}px;

  ${smallTablet} {
    width: 500px;
    padding: ${space * 2}px;
  }

  ${tablet} {
    width: 700px;
    flex-direction: row;
    padding-top: ${space * 4}px;
    padding-bottom: ${space * 4}px;
  }

  ${laptop} {
    width: 900px;
  }
`;

const Column = styled.div`
  flex: 1;
  padding: ${space * 2}px;
  text-align: center;
  color: ${fontDark};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${tablet} {
    &:first-child {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`;

const Themes = styled.div`
  display: flex;
  justify-content: center;
  gap: ${space}px;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto ${space * 3}px;
  max-width: 450px;
`;

const Theme = styled.div`
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  color: ${fontMedium};
  font-weight: 500;
  padding: ${space}px ${space * 2}px;
  ${tablet} {
    &:hover {
      color: ${fontDark};
      background-color: rgba(0, 0, 0, 0.07);
    }
  }
`;

const StyledLink = styled.a`
  color: ${cta};
  font-weight: 500;
  padding: ${space * 1}px;

  svg {
    margin-left: ${space}px;
  }

  &:hover {
    color: ${ctaOutline};
  }
`;

const ImageContainer = styled.div`
  margin-bottom: ${space * 2}px;
`;

const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: ${space * 2}px;
`;

const StyledBadge = styled(Badge)`
  border-radius: 8px;
  background-color: ${secondary};
  font-weight: 600;
  color: white;
  font-size: 12px;
  padding: 2px ${space}px;
  cursor: pointer;
`;

const StyledText = styled(Text)`
  color: ${fontLight};
`;

const DialogBookChoice = () => {
  const { isMobile } = useMediaQuery();

  return (
    <Container>
      <Column>
        <Title size="nm" as="h2" className="ml-16">
          Kies een leeg boek
          <Info align={isMobile ? 'right' : 'left'}>
            Bepaal zelf de teksten, vragen, achtergronden, stickers en meer.
          </Info>
        </Title>
        <StyledText size="nm" className="mb-24">
          Ga zelf aan de slag
        </StyledText>
        <ImageContainer>
          <Image
            src="https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/leeg_boek_keuze_LM-yz3vbM.png?updatedAt=1718284085776&ik-s=495b159b01f97082410d18a6e26aeb59978872cd"
            alt="Cover leeg boek"
            width={230}
            height={230}
            unoptimized={true}
          />
        </ImageContainer>
        <Actions>
          <Link href={bookBlank} passHref>
            <a>
              <Button iconRight={faArrowRightLong}>Begin nu</Button>
            </a>
          </Link>
          {isMobile && (
            <Link href={prices} passHref>
              <StyledLink>Vanaf €27,50</StyledLink>
            </Link>
          )}
        </Actions>
      </Column>
      <Column>
        <div>
          <Title size="nm" as="h2" className="ml-16">
            Of kies een thema
            <Info align="right">
              We hebben je al een handje geholpen door fotovlakken, tekstvlakken
              en leuke vragen te plaatsen. Je bent volledig vrij om het ontwerp
              naar wens aan te passen.
            </Info>
          </Title>
          <StyledText size="nm" className="mb-24">
            En pas alles aan naar wens
          </StyledText>
          <Themes>
            {themes.map((item, index) => (
              <Link href={item.url} passHref key={index}>
                <Theme>{item.theme}</Theme>
              </Link>
            ))}
          </Themes>
          {!isMobile && (
            <Link href={prices} passHref>
              <StyledBadge>Vanaf €27,50</StyledBadge>
            </Link>
          )}
        </div>
        <Link href={exampleBooks} passHref>
          <StyledLink>
            Voorbeelden bekijken
            <FontAwesomeIcon icon={faArrowRightLong} />
          </StyledLink>
        </Link>
      </Column>
    </Container>
  );
};

export default DialogBookChoice;
