import { faTimes, faCookieBite } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
  brand,
  fontDark,
  lineLight,
  secondary,
  secondary300,
} from '@constants/colors';
import { privacy } from '@constants/routes';
import { wrapperWidth } from '@constants/sizes';
import { space } from '@constants/spaces';
import Button from '@elements/Button';
import zindex from '@utils/zindex';

const Component = styled.div`
  background-color: white;
  border: 1px solid ${lineLight};
  border-radius: 16px;
  bottom: ${space * 3}px;
  box-shadow: 0px 0px 1px rgba(10, 22, 70, 0.06),
    0px 16px 16px -1px rgba(10, 22, 70, 0.1);
  margin-left: ${space * 3}px;
  max-width: 362px;
  padding: ${space * 3}px ${space * 2}px;
  position: fixed;
  right: ${space * 3}px;
  z-index: ${zindex('cookies')};
`;

const Wrapper = styled.div`
  margin: 0 auto;
  position: relative;
  padding: 0 ${space}px;
  max-width: ${wrapperWidth}};
`;

const Text = styled.p`
  margin-bottom: ${space}px;
  color: ${fontDark};
`;

const StyledLink = styled.a`
  position: relative;
  text-decoration: underline;
  color: ${brand};

  &:hover {
    text-decoration: none;
  }
`;

const Close = styled.button`
  background: none;
  position: absolute;
  top: ${space * 0.5}px;
  right: ${space * 1.5}px;
  cursor: pointer;
  color: ${fontDark};

  &::after {
    padding: 20px;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 42px;
  color: ${secondary};
`;

const IconContainer = styled.div`
  margin-bottom: ${space * 1.5}px;
  display: flex;
  align-items: center;
  width: 72px;
  height: 72px;
  background: ${secondary300};
  border-radius: 24px;
  justify-content: center;
`;

const calculateOneYearFromNow = () => {
  const now = new Date();
  now.setHours(now.getHours() + 8928);
  return now;
};

const CookieBar = () => {
  const router = useRouter();
  const printMatch = router.pathname.indexOf('/print') > -1;
  const [hideCookies, setHideCookies] = useState(true);

  useEffect(() => {
    const cookie = Cookies.get('cookie_message_shown');
    setHideCookies(cookie ? Boolean(cookie) : false);
  }, []);

  if (printMatch || hideCookies) return null;

  const acceptCookies = () => {
    const year = calculateOneYearFromNow();

    setHideCookies(true);
    Cookies.set('cookie_message_shown', 'true', {
      path: '/',
      expires: year,
    });
    Cookies.set('allow_functional', 'true', { path: '/', expires: year });
  };

  return (
    <Component>
      <Wrapper>
        <IconContainer>
          <Icon icon={faCookieBite} />
        </IconContainer>
        <Text className="mb-16">
          Wij gebruiken cookies om uw ervaring beter te maken. Om te voldoen aan
          de cookie wetgeving, vragen we je toestemming om de cookies te
          plaatsen.{' '}
          <Link href={privacy} passHref>
            <StyledLink>Meer informatie.</StyledLink>
          </Link>
        </Text>
        <Close onClick={acceptCookies} aria-label="Close cookies notice">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </Close>
        <Button
          set="primary"
          aria-label="Accept cookies"
          onClick={acceptCookies}
        >
          Toestaan
        </Button>
      </Wrapper>
    </Component>
  );
};

export default CookieBar;
