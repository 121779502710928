import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  .belco-widget-active {
    .belco-messenger-frame {
      bottom: 100px;
    }

    .belco-activator-frame {
      visibility: visible;
      bottom: 20px;
    }
  }

  .belco-activator-frame {
    visibility: hidden;
  }
`;
