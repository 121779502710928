import {
  faEllipsisH,
  faTrashAlt,
  faPencil, // faClone,
} from '@fortawesome/pro-light-svg-icons';
import { faBookOpen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';

import DropdownMenu from '@components/DropdownMenu';
import DropdownWrapper from '@components/DropdownWrapper';
import {
  lineLight,
  fontLight,
  info,
  ctaSecHover,
  brand,
  fontMedium,
} from '@constants/colors';
import { laptop, tablet } from '@constants/media-queries';
import { allProducts, book, books, login } from '@constants/routes';
import { space } from '@constants/spaces';
import Button from '@elements/Button';
import Input from '@elements/Input';
import Spinner from '@elements/Spinner';
import Text from '@elements/Text';
import Title from '@elements/Title';
import {
  Book,
  useBookRemoveMutation,
  useBookTitleUpdateMutation,
  useGetAccountInfoQuery,
  useGetBooksPageQuery,
} from '@graphql/generated/graphql';

const Component = styled.div`
  padding: ${space * 4}px;
  ${tablet} {
    width: 550px;
  }
  ${laptop} {
    width: 730px;
  }
`;

const Books = styled.ul`
  list-style-type: none;
  margin: 0;
`;

const Item = styled.li`
  display: flex;
  flex-direction: row;
  padding-top: ${space * 4}px;
  padding-bottom: ${space * 2 * 1.5}px;
  border-bottom: 1px solid ${lineLight};

  &:first-child {
    padding-top: 0;
  }

  h3 {
    margin-bottom: ${space}px;
  }

  span {
    display: block;
    font-size: 12px;
    color: ${fontLight};
    margin-bottom: ${space * 2}px;
  }
`;

const Tools = styled.div`
  margin-left: auto;
  margin-right: ${space * 2}px;
  cursor: pointer;
`;

const Actions = styled.div`
  margin-top: ${space * 4}px;
  & > *:not(:last-child) {
    margin-right: ${space * 2}px;
  }
`;

const Preview = styled.div`
  margin-right: ${space * 2}px;
`;

const BookTitle = styled.div<{ edit: boolean }>`
  display: flex;
  align-items: baseline;
  cursor: ${({ edit }) => (edit ? 'pointer' : 'default')};

  svg {
    margin-left: ${space * 2}px;
    color: ${info};
  }
`;

const Body = styled.div`
  text-align: center;
  padding: ${space * 3}px ${space * 2}px;
  margin: 0 auto;
  max-width: fit-content;
  color: ${fontMedium};
`;

const Icon = styled(FontAwesomeIcon)``;

const Container = styled.div<{ size: 'small' | 'large' }>`
  margin: 0 auto ${space * 4}px;
  display: flex;
  align-items: center;
  width: ${({ size }) => (size === 'small' ? '56px' : '96px')};
  height: ${({ size }) => (size === 'small' ? '56px' : '96px')};
  background: ${ctaSecHover};
  border-radius: ${({ size }) => (size === 'small' ? '24px' : '40px')};
  justify-content: center;

  & > ${Icon} {
    color: ${brand};
    font-size: ${({ size }) => (size === 'small' ? '22px' : '42px')};
  }
`;

const Delete = styled.div`
  padding: ${space * 3}px;
  border: 1px solid ${lineLight};
  border-radius: 8px;
`;

const Buttons = styled.div`
  margin-top: ${space * 3}px;
  display: flex;
  justify-content: space-between;
`;

const DialogBooks = () => {
  const router = useRouter();
  const { data, loading } = useGetBooksPageQuery({
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      if (error.message === 'FORBIDDEN')
        router.push({
          hash: login,
          query: { redirect: books },
        });
    },
  });
  const { data: account } = useGetAccountInfoQuery();
  const [bookRemove] = useBookRemoveMutation();
  const [bookTitleUpdate] = useBookTitleUpdateMutation();
  const {
    query: { bookId },
  } = router;
  const [editTitle, setEditTitle] = useState('');
  const [bookToDelete, setBookToDelete] = useState('');
  const formMethods = useForm({ shouldUnregister: true });
  const { handleSubmit } = formMethods;

  const deleteBook = (bookID: any) => {
    bookRemove({
      variables: { bookID },
      // remove deleted book from cache
      update(cache) {
        cache.modify({
          fields: {
            books(list, { readField }) {
              return list.filter(
                (book: Book) => readField('id', book) !== bookID,
              );
            },
          },
        });
      },
      onCompleted: () =>
        bookID === bookId ? router.push(`/404${books}`) : null,
    });
  };

  // const duplicatePage = (bookId) => {
  //   duplicateBook(bookId);
  // };

  const onBlur = (book: Book) => {
    const newTitle = formMethods.watch('title');

    bookTitleUpdate({
      variables: {
        bookID: book.id,
        input: newTitle,
      },
      onCompleted: () => setEditTitle(''),
    });
  };

  const onSubmit = () => {
    const newTitle = formMethods.watch('title');

    bookTitleUpdate({
      variables: {
        bookID: editTitle,
        input: newTitle,
      },
      onCompleted: () => setEditTitle(''),
    });
  };

  const unsetBook = () => {
    setBookToDelete('');
  };

  const doDelete = () => {
    deleteBook(bookToDelete);
    unsetBook();
  };

  const renderBook = (bk: Book) => {
    const bookDropdown = [
      // TODO: add env variable
      // {
      //   name: 'Dupliceren',
      //   onClick: () => duplicatePage(bk.id),
      //   icon: faClone,
      //   show: process.env.REACT_APP_ENVIRONMENT !== 'production',
      // },
      {
        name: 'Verwijder boek',
        onClick: () => setBookToDelete(bk.id),
        icon: faTrashAlt,
      },
    ];

    const bookDropdownComponent = <DropdownMenu items={bookDropdown} />;
    const isActiveBook = bookToDelete === bk.id;

    const confirmDelete = (
      <Delete>
        <Container size="small">
          <Icon icon={faTrashAlt} />
        </Container>
        <p>Weet je zeker dat je dit kunstwerk wilt verwijderen?</p>
        <Buttons>
          <Button onClick={unsetBook} set="tertiary">
            Annuleren
          </Button>
          <Button onClick={doDelete}>Ja, verwijderen</Button>
        </Buttons>
      </Delete>
    );

    const isOwner = bk.owner?.id === account?.account.id;

    return (
      <Item key={bk.id}>
        {/* 
        <Preview>
          <Image src="" alt="Book preview" />
          </Preview>
        */}
        <div>
          {editTitle === bk.id ? (
            <div>
              <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Input
                    name="title"
                    defaultValue={bk.title}
                    feedback={false}
                    onBlur={() => onBlur(bk)}
                    autoFocus
                  />
                </form>
              </FormProvider>
            </div>
          ) : (
            /* eslint-disable */
            <BookTitle
              edit={isOwner}
              onClick={() => (isOwner ? setEditTitle(bk.id) : {})}
            >
              <Title size="sm" as="h3">
                {bk.title}
              </Title>
              {isOwner && <FontAwesomeIcon icon={faPencil} />}
            </BookTitle>
            // /* eslint-enable */
          )}
          <span>
            Laatst bewerkt op: {`${moment(bk.modifiedAt).format('DD-MM-YYYY')}`}
          </span>
          <Actions>
            <Link href={book(bk.id, '')}>
              <a>
                <Button set="secondary">Bewerken</Button>
              </a>
            </Link>
          </Actions>
        </div>
        {isOwner && (
          <Tools>
            <DropdownWrapper
              dropdownChildren={
                isActiveBook ? confirmDelete : bookDropdownComponent
              }
              align="right"
              hideOnClick={isActiveBook ? true : false}
            >
              <FontAwesomeIcon icon={faEllipsisH} size="2x" />
            </DropdownWrapper>
          </Tools>
        )}
      </Item>
    );
  };

  const container = (children: React.ReactNode) => {
    return (
      <Component>
        <Title size="nm" as="h1" className="mb-32">
          Mijn boeken
        </Title>
        {children}
      </Component>
    );
  };

  if (loading) {
    return container(<Spinner />);
  }

  if (!data?.books.length) {
    return container(
      <Body>
        <Container size="large">
          <Icon icon={faBookOpen} />
        </Container>
        <Text size="md">Geen boeken gevonden</Text>
        <p className="mb-32">Ga naar ons aanbod en maak je eerste boek!</p>
        <Link href={allProducts}>
          <a>
            <Button>Kies je boek</Button>
          </a>
        </Link>
      </Body>,
    );
  }

  const BooksByLatest = [...data?.books].sort((a, b) =>
    a.modifiedAt < b.modifiedAt ? 1 : a.modifiedAt > b.modifiedAt ? -1 : 0,
  );

  return container(
    <Books>{BooksByLatest.map((bk) => renderBook(bk as Book))}</Books>,
  );
};

export default DialogBooks;
