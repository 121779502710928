import {
  faChevronLeft,
  faGridHorizontal,
} from '@fortawesome/pro-regular-svg-icons';
import { faComment } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import styled from 'styled-components';

import SaveButton from '@components/SaveButton';
import { black400, grey600, lineDark } from '@constants/colors';
import { isBrowser } from '@constants/live-prep';
import { book as bookRoute } from '@constants/routes';
import { space } from '@constants/spaces';
import { useBookContext } from '@hooks/useBookContext';

import MobileSpreadList from './MobileSpreadList';

const Component = styled.div`
  width: 100%;
  border-bottom: 1px solid transparent;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${space * 1.5}px;
  position: relative;
  box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.1),
    0px 0px 1px 0px rgba(10, 22, 70, 0.06);
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${space}px;
  padding: ${space}px ${space * 1.5}px;
  box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.1),
    0px 0px 1px 0px rgba(10, 22, 70, 0.06);
`;

const Cta = styled.div`
  display: flex;
  gap: ${space}px;
`;

const IconButton = styled.button`
  font-size: 12px;
  height: 20px;
  background: none;
  color: ${lineDark};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${space / 2}px;

  svg {
    height: 16px;
    color: ${black400};
  }
`;

const IconLink = styled.a`
  font-size: 12px;
  height: 20px;
  background: none;
  color: ${lineDark};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${space / 2}px;

  svg {
    color: ${black400};
  }
`;

const HelpdeskButton = styled(IconButton)`
  svg {
    color: ${grey600};
  }
`;

const Logo = styled.div`
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

type MobileHeaderProps = {
  showAllPages?: boolean;
  children?: React.ReactNode;
};

const MobileHeader = ({ showAllPages, children }: MobileHeaderProps) => {
  const [showSpreadList, setShowSpreadList] = useState(false);
  const {
    query: { bookId },
  } = useRouter();
  const { isAdmin, isOwner, participantStatus } = useBookContext();

  return (
    <Component>
      <Top>
        {showAllPages ? (
          <>
            <IconButton onClick={() => setShowSpreadList(true)}>
              <FontAwesomeIcon icon={faGridHorizontal} />
              Alle pagina&apos;s
            </IconButton>
            <MobileSpreadList
              open={showSpreadList}
              onClose={() => setShowSpreadList(false)}
            />
          </>
        ) : (
          <Link href={bookRoute(bookId as string, '')} passHref>
            <IconLink>
              <FontAwesomeIcon icon={faChevronLeft} />
              Naar boek
            </IconLink>
          </Link>
        )}
        <Link href="/">
          <a>
            <Logo>
              <Image
                src="/images/logo.svg"
                alt="Logo"
                width={110}
                height={12}
              />
            </Logo>
          </a>
        </Link>
        <HelpdeskButton onClick={() => isBrowser && window.Belco.toggle()}>
          <FontAwesomeIcon icon={faComment} />
          Helpdesk
        </HelpdeskButton>
      </Top>
      {children && (participantStatus || isOwner || isAdmin) && (
        <Bottom>
          <SaveButton />
          <Cta>{children}</Cta>
        </Bottom>
      )}
    </Component>
  );
};

export default MobileHeader;
