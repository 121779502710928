import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

const BelcoWidget = ({ config, userId, onLoad, onReady, onStatusChanged }) => {
  const [initialized, setInitialized] = useState(false);

  const init = () => {
    setInitialized(true);

    if (typeof onLoad === 'function') {
      onLoad();
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') return;

    if (document.getElementById('belco-js')) {
      // already loaded
      return init();
    }

    /* eslint-disable */
    !(function (n, o) {
      const e = window.belcoFunction || 'Belco';
      window[e] ||
        (window[e] = function (n) {
          if (void 0 === window[e][n]) throw new Error('Unknown method');
          return window[e][n].apply(
            window[e],
            Array.prototype.slice.call(arguments, 1),
          );
        }),
        (window[e]._q = []);
      for (
        let i = [
            'init',
            'sync',
            'track',
            'page',
            'open',
            'close',
            'toggle',
            'on',
            'once',
            'off',
            'anonymousId',
            'customer',
            'reset',
            'sendMessage',
          ],
          t = function (n) {
            return function () {
              const o = Array.prototype.slice.call(arguments);
              return o.unshift(n), window[e]._q.push(o), window[e];
            };
          },
          w = 0;
        w < i.length;
        w++
      ) {
        const r = i[w];
        window[e][r] = t(r);
      }
      (window[e].load = function (e) {
        if (!n.getElementById('belco-js')) {
          const i = n.createElement(o);
          (i.async = !0),
            (i.id = 'belco-js'),
            (i.type = 'text/javascript'),
            (i.src = '//cdn.belco.io/v2/widget.js'),
            (i.onload = function (n) {
              typeof e === 'function' && e(n);
            });
          const t = n.getElementsByTagName(o)[0];
          t.parentNode.insertBefore(i, t);
        }
      }),
        window.belcoConfig &&
          window[e].load(() => {
            window[e]('init', window.belcoConfig);
          });
    })(document, 'script');
    /* eslint-enable */

    window.Belco?.load(init);
    return undefined;
  }, []);

  useEffect(() => {
    if (initialized) return;
    if (config) {
      window.Belco?.init(config);
    } else {
      console.error('Config is required to init Belco');
    }
  }, [initialized, config]);

  useEffect(() => {
    // Reset widget when users log out
    if (!userId) {
      window.Belco?.reset();
    }
  }, [userId]);

  useEffect(() => {
    if (onReady) {
      window.Belco?.on('ready', onReady);
    }

    return () => {
      if (onReady) {
        window.Belco?.off('ready', onReady);
      }
    };
  }, [onReady]);

  useEffect(() => {
    if (onStatusChanged) {
      window.Belco?.on('status-changed', onStatusChanged);
    }

    return () => {
      if (onStatusChanged) {
        window.Belco?.off('status-changed', onStatusChanged);
      }
    };
  }, [onStatusChanged]);

  return null;
};

BelcoWidget.propTypes = {
  config: PropTypes.shape({}),
  userId: PropTypes.string,
  onLoad: PropTypes.func,
  onReady: PropTypes.func,
  onStatusChanged: PropTypes.func,
};

export default BelcoWidget;
