import { faArrowLeftLong, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import SpreadPreview from '@components/SpreadPreview';
import { ctaOutline, cta, fontDark } from '@constants/colors';
import { space } from '@constants/spaces';
import { useBookContext } from '@hooks/useBookContext';
import { getSpreadName } from '@utils/string';
import zindex from '@utils/zindex';

const Component = styled.div<{ open: boolean }>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  background-color: white;
  color: ${fontDark};
  display: flex;
  flex-direction: column;
  z-index: ${zindex('spread-overview')};
  transition: transform 300ms ease-out;

  h3 {
    flex: 1 0 auto;
  }
`;

const Container = styled.div`
  overflow-y: auto;
  padding: 0 ${space * 4}px ${space * 4}px;
`;

const BackLink = styled.button`
  color: ${cta};
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${space}px;
  background-color: white;
  margin-top: ${space * 2}px;
  &:hover {
    color: ${ctaOutline};
    cursor: pointer;
  }
`;

const SpreadList = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  gap: ${space * 1.5}px;
  max-width: 400px;
`;

const Top = styled.div`
  display: flex;
  padding: ${space * 3}px ${space * 4}px;
`;

const Close = styled.button`
  color: ${fontDark};
  background: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

type Props = {
  open: boolean;
  onClose: () => void;
};

const MobileSpreadList = ({ open, onClose }: Props) => {
  const { book } = useBookContext();

  if (!book) return null;

  // Check if the book has invited users by finding any spread that is collaborative but not a template
  const hasInvitedUsers = book.spreads.some(
    (spread) => spread.isCollaborative && !spread.isTemplate,
  );

  // Determine if user is on a template page
  const isOnTemplatePage = false;

  // Filter out the hidden template spreads, so only visible spreads are included
  const visibleSpreads = book.spreads.filter(
    (spread) => !(!isOnTemplatePage && spread.isTemplate),
  );

  // Counter for non-template spreads, used to generate page numbers for non-template spreads
  let nonTemplateSpreadCounter = 0;

  return (
    <Component open={open}>
      <Top>
        <h3>Alle pagina&apos;s</h3>
        <Close onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </Close>
      </Top>
      <Container>
        <SpreadList onClick={onClose}>
          {visibleSpreads.map((spread, visibleIndex) => {
            // Check if the spread is a template
            const isTemplateSpread = spread.isTemplate;

            // Generate the title for non-template spreads with page number
            const title = isTemplateSpread
              ? getSpreadName(
                  visibleIndex,
                  visibleSpreads.length,
                  isTemplateSpread,
                )
              : getSpreadName(
                  nonTemplateSpreadCounter++,
                  visibleSpreads.length,
                  isTemplateSpread,
                );

            return (
              <SpreadPreview
                key={spread.id}
                title={title}
                spread={spread}
                book={book}
                disabled={!spread.isEditable}
              />
            );
          })}
        </SpreadList>
        <BackLink onClick={onClose}>
          <FontAwesomeIcon icon={faArrowLeftLong} />
          Terug naar boek
        </BackLink>
      </Container>
    </Component>
  );
};

export default MobileSpreadList;
